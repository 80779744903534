export const data = 
     [
        {
            "src":"https://picsum.photos/seed/img1/600/400",
            "alt":"image 1 carousel"

        },
        {
            "src":"https://picsum.photos/seed/img1=2/600/400",
            "alt":"image 2 carousel"

        },
        {
            "src":"https://picsum.photos/seed/img1=3/600/400",
            "alt":"image 3 carousel"

        },
        {
            "src":"https://picsum.photos/seed/img4/600/400",
            "alt":"image 4 carousel"

        }
    ]
